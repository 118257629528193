<script lang="ts">
	import { onMount, onDestroy } from 'svelte';
	import { page } from '$app/stores';
	import type { User } from 'firebase/auth';
	import authStore from '$lib/authStore';
	import Header from '$components/Header.svelte';
	import LoginOrSignUpModal from '$components/LoginOrSignUpModal.svelte';
	import { posthog, initPosthog } from '$lib/initPosthog';
	import { browser } from '$app/environment';
	import GoogleOneTapSignIn from './GoogleOneTapSignIn.svelte';

	let subscriptions: (() => void)[] = [];
	export let user: User | null | undefined = undefined;
	let firstAuthCheck: boolean = true;

	export let link: string = '/canvas';
	export let loginOrSignUpModal: LoginOrSignUpModal;
	export let loginOrSignupWhichModal: 'login' | 'sign-up' = 'sign-up';

	onMount(async () => {
		if (!browser) {
			return;
		}

		initPosthog();
		if ($page.url.pathname) {
			localStorage.setItem('redirectUrl', $page.url.pathname);
		}

		document.body.style.overflowY = 'auto';
		document.body.style.background = '#e6e4d6';

		subscriptions.push(
			authStore.subscribe(async (auth) => {
				user = auth.user;

				// we don't know their sign-in status yet
				if (user === undefined) {
					return;
				}

				// user is not logged in
				if (user === null) {
					return;
				}

				if (user.email) {
					posthog.identify(user.email);
				}

				firstAuthCheck = false;
			})
		);
	});

	onDestroy(() => {
		for (const _ of subscriptions) {
			_();
		}
	});
</script>

<div class="w-screen min-h-screen h-fit font-inter bg-primary-100 overflow-x-hidden">
	<div
		id="page"
		class="w-screen h-fit flex flex-col"
		style="
			background-image: linear-gradient(
			180deg,
			hsl(56deg 100% 97%) 0%,
			hsl(56deg 80% 96%) 8%,
			hsl(56deg 67% 95%) 17%,
			hsl(55deg 57% 94%) 25%,
			hsl(55deg 50% 93%) 33%,
			hsl(55deg 44% 93%) 42%,
			hsl(54deg 40% 92%) 50%,
			hsl(54deg 36% 91%) 58%,
			hsl(54deg 33% 90%) 67%,
			hsl(53deg 30% 89%) 75%,
			hsl(53deg 28% 89%) 83%,
			hsl(53deg 26% 88%) 92%,
			hsl(53deg 24% 87%) 100%
		);"
	>
		<div class="w-full h-12 p-2 flex flex-row justify-between">
			<Header />
			<div class="flex flex-row justify-center items-center">
				<div
					class="p-0.5 flex flex-row justify-center items-center bg-primary-50 border border-primary-200 rounded"
				>
					<a
						href="https://discord.gg/hbb2qD57hM"
						target="_blank"
						aria-label="Iliad Discord"
						class="w-8 h-7 flex flex-row justify-center items-center rounded bg-primary-50 hover:brightness-90"
					>
						<i class="fa-brands fa-discord"></i>
					</a>
					<div class="w-0.5"></div>
					<a
						class="w-[5rem] h-7 flex flex-row justify-center items-center rounded text-zinc-900 bg-primary-50 hover:brightness-90 font-semibold text-sm"
						href="/plan"
					>
						Pricing
					</a>
					<div class="w-0.5"></div>
					{#if user}
						<div
							class="w-[7rem] h-7 flex flex-row justify-center items-center rounded text-emerald-800 bg-primary-50 border-zinc-400 font-semibold text-sm"
						>
							Logged in <i class="fa-solid fa-check ml-2 text-emerald-800"></i>
						</div>
					{:else}
						<button
							class="w-[7rem] h-7 flex flex-row justify-center items-center rounded text-zinc-900 bg-primary-50 hover:brightness-90 border-zinc-400 font-semibold text-sm"
							on:click={() => {
								if (user === undefined) {
									return;
								}
								loginOrSignupWhichModal = 'login';
								loginOrSignUpModal.openDialog();
							}}
						>
							Log in <i class="fa-solid fa-sign-in ml-2"></i>
						</button>
						{#if user === null && firstAuthCheck}
							<GoogleOneTapSignIn />
						{/if}
					{/if}
				</div>
				<div class="w-2"></div>
			</div>
		</div>
		<div class="w-full h-fit flex flex-col justify-start items-center">
			<slot></slot>
		</div>
	</div>
</div>

<LoginOrSignUpModal
	{link}
	bind:this={loginOrSignUpModal}
	bind:whichModal={loginOrSignupWhichModal}
/>
