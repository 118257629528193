<script lang="ts">
	import { onMount } from 'svelte';
	import { auth } from '$lib/firebase';
	import { signInWithCredential, GoogleAuthProvider } from 'firebase/auth';
	import { PUBLIC_ENVIRONMENT } from '$env/static/public';

	// Select the correct client ID based on environment
	const clientId =
		PUBLIC_ENVIRONMENT === 'prod'
			? '820767175720-ekvrh4fjmnga4e6pt2qs81362n4svhre.apps.googleusercontent.com'
			: '114296571634-avi4bhm8icnvmed8rs95cndhght78ajp.apps.googleusercontent.com';

	onMount(() => {
		// First, define the global callback function
		window.handleGoogleOneTapResponse = (response) => {
			const idToken = response.credential;
			const credential = GoogleAuthProvider.credential(idToken);

			signInWithCredential(auth, credential).catch((error) => {
				console.error('Google One Tap sign-in error:', error);
			});
		};

		// Then, initialize Google One Tap programmatically after the function is defined
		if (window.google && window.google.accounts) {
			initializeGoogleOneTap();
		} else {
			// Load the Google Identity Services script
			const script = document.createElement('script');
			script.src = 'https://accounts.google.com/gsi/client';
			script.async = true;
			script.defer = true;
			script.onload = initializeGoogleOneTap;
			document.head.appendChild(script);
		}
	});

	function initializeGoogleOneTap() {
		window.google.accounts.id.initialize({
			client_id: clientId,
			callback: window.handleGoogleOneTapResponse,
			auto_select: true,
			cancel_on_tap_outside: true,
			itp_support: false
		});

		// Display the One Tap UI
		window.google.accounts.id.prompt();
	}
</script>

<!-- No need for the div element when using the programmatic approach -->
